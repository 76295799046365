import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { lazy, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from './components/layouts/Layout';
import { GraphQLClient } from './config/apollo';
import { localeKeys } from './config/i18next';
import { SIDEBAR_LINKS_DATA } from './constants/mon-profil';
import {
  ARTICLE_EMB,
  ARTICLE_MEDEIS,
  BIBLIOTHEQUE_SAVOIR,
  BRIQUE_DE_SAVOIR,
  ERREUR_TECHNIQUE,
  ESPACE_PRIVE,
  EVENEMENTS,
  FORMULAIRE_INSCRIPTION,
  GENERIC_CONFIRMATION_PAGE,
  HOMEPAGE_EMB,
  HOMEPAGE_MEDEIS,
  HOMEPAGE_SAGA_MICROBIOTES,
  NON_DISPONIBLE,
  PARCOURS_FORMATION,
  PARCOURS_FORMATION_RESULT,
  PREVIEW,
  PROFIL,
  TEST_ORIENTATION,
  TEST_ORIENTATION_RESULT
} from './constants/routing';
import { DidomiContextProvider } from './context/DidomiContextProvider';
import { UserContextProvider } from './context/UserContextProvider';
import { withSuspense } from './hoc/with-suspens';
import { CommonRichtextStyle } from './models/dynamic-zone/componentGeneriqueTexte';
import { GenericConfirmationPage } from './pages/GenericConfirmationPage';
import NotAvailableInCurrentLanguage from './pages/NotAvailableInCurrentLanguage';
import NotFoundPage from './pages/NotFoundPage';
import { PageError } from './pages/PageError';
import theme from './theme';

/**
 * Scroll top on each route change
 */
const ScrollToTop = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{children}</>;
};

const NavigateToLang = ({ locale }: { locale: string }) => {
  const { pathname } = useLocation();
  return <Navigate to={`/${locale}${pathname === '/' ? '' : pathname}`} replace />;
};

export type RoutePageProps = { locale: string; commonRichtextStyle?: CommonRichtextStyle; slug?: string };

const App = () => {
  return (
    <ApolloProvider client={GraphQLClient}>
      <UserContextProvider>
        <DidomiContextProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <ScrollToTop>
                <Routes>
                  {localeKeys.map((locale) => {
                    const withLazy = (pagePath: string) =>
                      withSuspense(
                        lazy(
                          () =>
                            import(`./pages/${pagePath}`) as Promise<{
                              default: ({ locale, slug }: RoutePageProps) => JSX.Element;
                            }>
                        ),
                        { locale }
                      );
                    const Profile = lazy(() => import('./pages/MonProfil'));

                    return (
                      <Route key={locale} path={locale} element={<Layout locale={locale} />}>
                        {/* PUBLIC */}
                        <Route index element={withLazy('HomepageDeconnecte')} />
                        <Route path={FORMULAIRE_INSCRIPTION} element={withLazy('FormulaireInscription')} />
                        <Route path={GENERIC_CONFIRMATION_PAGE} element={<GenericConfirmationPage />} />
                        <Route path={':slug/'} element={withLazy('PagePublique')} />
                        <Route path={`${HOMEPAGE_EMB}`} element={withLazy('HomepageEmb')} />
                        <Route path={`${ARTICLE_EMB}/:slug`} element={withLazy('ArticleEmbPage')} />

                        {/* PREVIEW */}
                        <Route path={PREVIEW}>
                          <Route path={`${BRIQUE_DE_SAVOIR}/:slug`} element={withLazy('BriqueSavoirPreview')} />
                          <Route path={`${ARTICLE_EMB}/:slug`} element={withLazy('ArticleEmbPreview')} />
                        </Route>

                        {/* PRIVATE */}
                        <Route path={ESPACE_PRIVE}>
                          <Route index element={withLazy('HomepageConnecte')} />

                          {/* Singles */}
                          {SIDEBAR_LINKS_DATA.map(({ slug }) => {
                            return (
                              <Route
                                key={slug}
                                path={`${PROFIL}/${slug}`}
                                element={withSuspense(Profile, { locale, slug })}
                              />
                            );
                          })}
                          <Route path={`${HOMEPAGE_MEDEIS}`} element={withLazy('HomepageMedeis')} />
                          <Route path={`${HOMEPAGE_SAGA_MICROBIOTES}`} element={withLazy('HomepageSagaMicrobiotes')} />

                          {/* Collections Lists */}
                          <Route path={PARCOURS_FORMATION} element={withLazy('NosParcoursFormation')} />
                          <Route path={BIBLIOTHEQUE_SAVOIR} element={withLazy('BibliothequeSavoir')} />
                          <Route path={EVENEMENTS} element={withLazy('Agenda')} />

                          {/* Collection Singles */}
                          <Route path={`${BRIQUE_DE_SAVOIR}/:slug`} element={withLazy('BriqueSavoirPage')} />
                          <Route path={`${ARTICLE_MEDEIS}/:slug`} element={withLazy('ArticleMedeisPage')} />
                          <Route path={`${PARCOURS_FORMATION}/:slug`} element={withLazy('ParcoursFormationPage')} />
                          <Route
                            path={`${PARCOURS_FORMATION}/:slug/${PARCOURS_FORMATION_RESULT}`}
                            element={withLazy('ParcoursFormationResult')}
                          />
                          <Route path={`${TEST_ORIENTATION}/:thematique`} element={withLazy('TestOrientation')} />
                          <Route
                            path={`${TEST_ORIENTATION}/:thematique/${TEST_ORIENTATION_RESULT}`}
                            element={withLazy('TestOrientationResult')}
                          />
                          <Route path={`${EVENEMENTS}/:slug/`} element={withLazy('Evenement')} />
                          <Route path={':slug/'} element={withLazy('PagePrivee')} />
                        </Route>

                        {/* Others */}
                        <Route path={NON_DISPONIBLE} element={<NotAvailableInCurrentLanguage />} />
                        <Route path={ERREUR_TECHNIQUE} element={<PageError locale={locale} />} />
                        <Route path="*" element={<NotFoundPage />} />
                      </Route>
                    );
                  })}
                  <Route path="*" element={<NavigateToLang locale="fr" />} />
                </Routes>
              </ScrollToTop>
            </BrowserRouter>
          </ThemeProvider>
        </DidomiContextProvider>
      </UserContextProvider>
    </ApolloProvider>
  );
};

export default App;
